import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const { REACT_APP_API_URL } = process.env;

export default function Forgot() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      toast.error("Please enter your email");
    } else {
      axios
        .post(`${REACT_APP_API_URL}/service-provider-users/forgot`, { email })
        .then(() => {
          toast.success("Password reset email sent");
          navigate("/", { replace: true });
        })
        .catch(() => {
          toast.success("Password reset email sent");
          navigate("/", { replace: true });
        });
    }
  };

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-13 w-auto"
          src="/images/logos/login-logo.png"
          alt="Capital Now Funding Law Firm Portal"
        />
        <h2 className="mt-6 text-center text-sm text-gray-600">
          Please enter the email address associated with your account and We will email you a link to reset your
          password.
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="text-right">
              <div className="text-sm">
                <a href="/login" className="font-medium text-brand-400 hover:text-brand-600">
                  Return to login?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-brand-400 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-400"
                onClick={handleSubmit}
              >
                Request Password Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
