import React, { useState } from "react";
import { toast } from "react-toastify";
import Breadcrumb from "../../components/Breadcrumb";
import SettingTabs from "../../layouts/FullLayout/SettingTabs/SettingTabs";
import { client, clientError } from "../../libs/Client.jsx";
import { useUser } from "../../hooks/Authentication";

export default function Password() {
  const { user } = useUser();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const pages = [{ name: "Settings", href: "/settings", current: true }];

  function handleUpdate() {
    if (currentPassword === "") {
      toast.error("Current password required");
    } else if (newPassword === "") {
      toast.error("New password required");
    } else if (newPassword !== confirmPassword) {
      toast.error("Passwords must match");
    } else {
      client()
        .put(`/service-provider-portal/me/password`, {
          currentPassword,
          newPassword,
        })
        .then(() => {
          toast.success("Password changed");
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        })
        .catch((err) => {
          clientError(err);
        });
    }
  }

  return (
    <main className="flex-1">
      <Breadcrumb pages={pages} />
      <div className="py-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-2">
            <div className="max-w-7xl mx-auto">
              <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
            </div>
            <SettingTabs role={user.role} selected="Password" />
            <div className="divide-y divide-gray-200">
              <div className="mt-8flex flex-col">
                <div className="pt-0">
                  <div className="mt-0 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                      <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                        Current Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="currentPassword"
                          id="currentPassword"
                          className="shadow-sm focus:ring-brand-400 focus:border-brand-400 block w-full sm:text-sm border-gray-300 rounded-md"
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          value={currentPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                      <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                        New Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="newPassword"
                          id="newPassword"
                          className="shadow-sm focus:ring-brand-400 focus:border-brand-400 block w-full sm:text-sm border-gray-300 rounded-md"
                          onChange={(e) => setNewPassword(e.target.value)}
                          value={newPassword}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                      <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                        Confirm New Password
                      </label>
                      <div className="mt-1">
                        <input
                          type="password"
                          name="confirmPassword"
                          id="confirmPassword"
                          className="shadow-sm focus:ring-brand-400 focus:border-brand-400 block w-full sm:text-sm border-gray-300 rounded-md"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          value={confirmPassword}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <div className="flex ">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-400 hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-400"
                      onClick={handleUpdate}
                    >
                      Update Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
