import PropTypes from "prop-types";

export default function EmptyRows(props) {
  return (
    <div>
      <div className="text-center p-16">
        <h3 className="mt-2 text-base font-medium text-gray-900">
          {props.title}
        </h3>
        <p className="mt-1 text-base text-gray-500">{props.subtitle}</p>
      </div>
    </div>
  );
}

EmptyRows.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};
