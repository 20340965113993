import React, { useEffect, useState } from "react";
import { client, clientError } from "../../libs/Client";
import { shortDate, reportDate, currencyNoCents } from "../../libs/Utils";
import LawFirmAddress from "../../components/Statements/LawFirmAddress";
import StatementAddress from "../../components/Statements/StatementAddress";
import "../../print.css";

export default function SummaryStatementPrintView() {
  const [serviceProvider, setServiceProvider] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [total, setTotal] = useState(0);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    client()
      .get(`/service-provider-portal/statements/summary`)
      .then((res) => {
        setTotal(res.data.total);
        setLineItems(res.data.lineItems);
        setServiceProvider(res.data.serviceProvider.name);
        setStreetAddress(res.data.serviceProvider.streetAddress);
        setCity(res.data.serviceProvider.city);
        setState(res.data.serviceProvider.state.label);
        setZipCode(res.data.serviceProvider.zip);
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);

  return (
    <div>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <LawFirmAddress
                name={serviceProvider}
                street={streetAddress}
                city={city}
                state={state}
                zipCode={zipCode}
              />
            </td>
            <td align="right">
              <StatementAddress />
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <center>
          <u>Statement as of {reportDate()}</u>
        </center>
      </p>
      <p>&nbsp;</p>
      <table className="main-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th align="left">Client (Date of Accident)</th>
            <th align="left">Primary Law Firm Contact</th>
            <th align="right">Total Payoff</th>
          </tr>
        </thead>
        <tbody>
          {lineItems.map((item, idx) => (
            <tr key={idx}>
              <td align="left">
                {item.fullName} ({shortDate(item.accidentDate)})
              </td>
              <td align="left">{item.contact}</td>
              <td align="right">{currencyNoCents(item.totalPayoff)}</td>
            </tr>
          ))}
          <tr>
            <td align="left" colSpan={2}>
              <strong>Total</strong>
            </td>
            <td align="right">
              <strong>{currencyNoCents(total)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
