import { mainNavigation, statementsNavigation } from "./SidebarMenuItems";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar() {
  return (
    <div className="flex flex-col flex-grow border-r border-brand-100 pt-5 white overflow-y-auto">
      <div className="flex items-center flex-shrink-0 px-4">
        <img
          className="h-10 w-auto"
          src="/images/logos/logo.png"
          alt="Capital Now Funding Law Firm Portal"
        />
      </div>
      <div className="mt-5 flex-grow flex flex-col">
        <nav className="flex-1 px-2 pb-4 space-y-1">
          {mainNavigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className={classNames(
                item.current
                  ? "bg-brand-600 text-white"
                  : "text-brand-100 hover:text-brand-600 hover:no-underline",
                "group flex items-center px-2 py-2 text-sm rounded-md"
              )}
            >
              <item.icon
                className={classNames(
                  item.current
                    ? "text-brand-100"
                    : "text-gray-600 group-hover:text-brand-600",
                  "mr-3 flex-shrink-0 h-6 w-6"
                )}
                aria-hidden="true"
              />
              {item.name}
            </a>
          ))}
          <div>
            <h3
              className="px-3 text-sm font-medium text-brand-100 mt-6"
              id="mobile-teams-headline"
            >
              Statements
            </h3>
            <div
              className="mt-1 space-y-1"
              role="group"
              aria-labelledby="mobile-teams-headline"
            >
              {statementsNavigation.map((statement) => (
                <a
                  key={statement.name}
                  href={statement.href}
                  className="group flex items-center px-3 py-2 text-sm leading-5 text-brand-100 hover:text-brand-600 hover:no-underline"
                >
                  <span
                    className={classNames(
                      statement.bgColorClass,
                      "w-1 h-1 mr-4 rounded-full"
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{statement.name}</span>
                </a>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
