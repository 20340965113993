import { HomeIcon } from "@heroicons/react/24/outline";

export const mainNavigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon, current: false },
];

export const statementsNavigation = [
  {
    name: "Summary",
    href: "/summary",
    bgColorClass: "bg-brand-600",
  },
  {
    name: "Detailed",
    href: "/detailed",
    bgColorClass: "bg-brand-600",
  },
  {
    name: "Aging",
    href: "/aging",
    bgColorClass: "bg-brand-600",
  },
];
