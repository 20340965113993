import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Login from "./views/Authentication/Login";
import Forgot from "./views/Authentication/Forgot";
import Reset from "./views/Authentication/Reset";

import FullLayout from "./layouts/FullLayout";

import AgingStatement from "./views/Statements/AgingStatement";
import AgingStatementPrintView from "./views/PrintView/AgingStatementPrintView";
import Dashboard from "./views/dashboards/Dashboard";
import DetailedStatement from "./views/Statements/DetailedStatement";
import DetailStatementPrintView from "./views/PrintView/DetailStatementPrintView";
import Password from "./views/Settings/Password";
import Settings from "./views/Settings/Settings";
import SummaryStatement from "./views/Statements/SummaryStatement";
import SummaryStatementPrintView from "./views/PrintView/SummaryStatementPrintView";

import { useUser } from "./hooks/Authentication";

function App() {
  const { user, setUser } = useUser();

  if (!user) {
    return (
      <div>
        <Routes>
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/login" element={<Login setUser={setUser} />} />
          <Route path="/reset/:token" element={<Reset />} />
          <Route path="*" element={<Navigate to={user ? "/" : "/login"} />} />
        </Routes>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<FullLayout />}>
          <Route path="/" exact element={<Dashboard />} />
          <Route path="/login" exact element={<Navigate replace to="/" />} />
          <Route path="/aging" exact element={<AgingStatement />} />
          <Route path="/detailed" exact element={<DetailedStatement />} />
          <Route path="/settings/password" exact element={<Password />} />
          <Route path="/summary" exact element={<SummaryStatement />} />
        </Route>
        <Route path="/print-views/aging-statement" exact element={<AgingStatementPrintView />} />
        <Route path="/print-views/detail-statement" exact element={<DetailStatementPrintView />} />
        <Route path="/print-views/summary-statement" exact element={<SummaryStatementPrintView />} />
        <Route path="/settings" exact element={<Settings />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
