import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { Transition } from "@headlessui/react";

import { useUser, signOut } from "../../hooks/Authentication";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const { user, setUser } = useUser();

  const handleLogout = () => {
    signOut();
    setUser(null);
    window.location.reload();
  };

  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="bg-gray-200 rounded-md flex items-center p-2 text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-400">
          <span className="sr-only">Open user menu</span>
          {user.firstName} {user.lastName}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item key="My Profile">
            {({ active }) => (
              <Link
                to="/settings"
                className={classNames(
                  active ? "bg-gray-100" : "",
                  "block px-4 py-2 text-sm text-gray-700"
                )}
              >
                Settings
              </Link>
            )}
          </Menu.Item>
          <hr />
          <Menu.Item key="Sign Out">
            {({ active }) => (
              <Link
                to="/"
                className={classNames(
                  active ? "bg-gray-100" : "",
                  "block px-4 py-2 text-sm text-gray-700"
                )}
                onClick={handleLogout}
              >
                Sign Out
              </Link>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
