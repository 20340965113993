import React, { useEffect, useState } from "react";
import { client, clientError } from "../../libs/Client";
import { currency, shortDate, nationalPhoneNumber } from "../../libs/Utils";
import Breadcrumb from "../../components/Breadcrumb";
import EmptyRows from "../../components/EmptyRows";

const pages = [{ name: "Dashboard", current: true }];

export default function Dashboard() {
  const [caseList, setCaseList] = useState([]);

  useEffect(() => {
    client()
      .get(`/service-provider-portal/lawsuits`)
      .then((res) => {
        console.log(res.data);
        setCaseList(res.data);
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);
  return (
    <main className="flex-1">
      <Breadcrumb pages={pages} />
      <div className="pt-4 px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {caseList.length > 0 ? (
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Case #
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Plaintiff
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Plaintiff Phone
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Plaintiff Address
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Date of Accident
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                            Total Payoff
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {caseList.map((item) => (
                          <tr key={item.id}>
                            <td className="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-500">{item.id}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.name}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {nationalPhoneNumber(item.phone)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.address}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {shortDate(item.accidentDate)}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              {currency(item.totalPayoff)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <EmptyRows title="No Cases" subtitle="There are no cases to display" />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
