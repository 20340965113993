import React, { useEffect, useState, Fragment } from "react";
import { client, clientError } from "../../libs/Client";
import { currencyNoCents, reportDate, shortDate } from "../../libs/Utils";
import LawFirmAddress from "../../components/Statements/LawFirmAddress";
import StatementAddress from "../../components/Statements/StatementAddress";
import "../../print.css";

export default function AgingStatementPrintView() {
  const [serviceProvider, setServiceProvider] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [aTotal, setATotal] = useState(0);
  const [bTotal, setBTotal] = useState(0);
  const [cTotal, setCTotal] = useState(0);
  const [dTotal, setDTotal] = useState(0);
  const [eTotal, setETotal] = useState(0);
  const [fTotal, setFTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    client()
      .get(`/service-provider-portal/statements/aging`)
      .then((res) => {
        setServiceProvider(res.data.serviceProvider.name);
        setStreetAddress(res.data.serviceProvider.streetAddress);
        setCity(res.data.serviceProvider.city);
        setState(res.data.serviceProvider.state.label);
        setZipCode(res.data.serviceProvider.zip);
        setATotal(res.data.aTotal);
        setBTotal(res.data.bTotal);
        setCTotal(res.data.cTotal);
        setDTotal(res.data.dTotal);
        setETotal(res.data.eTotal);
        setFTotal(res.data.fTotal);
        setLineItems(res.data.lineItems);
        setTotal(res.data.total);
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);

  return (
    <div>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <LawFirmAddress
                name={serviceProvider}
                street={streetAddress}
                city={city}
                state={state}
                zipCode={zipCode}
              />
            </td>
            <td align="right">
              <StatementAddress />
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <center>
          <strong>
            <u>Statement as of {reportDate()}</u>
          </strong>
          <br />
          with aging detail
        </center>
      </p>
      <p>&nbsp;</p>
      <table className="main-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th scope="col" className="px-3 py-4 text-center text-xs font-semibold text-gray-900" colSpan={6}>
              Days Aging
            </th>
            <th></th>
          </tr>
          <tr>
            <th align="left">
              Client (Date of Accident)
              <br />
              <span className="ml-4">Date of Funding</span>
            </th>
            <th align="left">Contact</th>
            <th align="right">
              0 - 6<br />
              Months
            </th>
            <th align="right">
              6 - 12
              <br />
              Months
            </th>
            <th align="right">
              12 - 18
              <br />
              Months
            </th>
            <th align="right">
              18 - 24
              <br />
              Months
            </th>
            <th align="right">
              24 - 36
              <br />
              Months
            </th>
            <th align="right">
              36
              <br />
              Months +
            </th>
            <th align="right">Total Payoff</th>
          </tr>
        </thead>
        <tbody>
          {lineItems.map((item, idx) => (
            <Fragment key={idx}>
              <tr key={item.fullName}>
                <td>
                  {item.fullName} ({shortDate(item.accidentDate)})
                </td>
                <td>{item.contact}</td>
                <td colSpan={7}></td>
              </tr>
              {item.applications.map((application, idx) => (
                <tr key={idx}>
                  <td colSpan={2}>&nbsp;&nbsp;&nbsp;&nbsp;{shortDate(application.createdAt)}</td>
                  <td align="right">{currencyNoCents(application.a)}</td>
                  <td align="right">{currencyNoCents(application.b)}</td>
                  <td align="right">{currencyNoCents(application.c)}</td>
                  <td align="right">{currencyNoCents(application.d)}</td>
                  <td align="right">{currencyNoCents(application.e)}</td>
                  <td align="right">{currencyNoCents(application.f)}</td>
                  <td align="right">{currencyNoCents(application.total)}</td>
                </tr>
              ))}
              {item.partialPaymentTotal > 0 && (
                <tr>
                  <td colSpan={8} align="left">
                    Partial Payments
                  </td>
                  <td align="right">{currencyNoCents(-item.partialPaymentTotal)}</td>
                </tr>
              )}
              <tr>
                <td colSpan={2} align="left">
                  <strong>Case Total</strong>
                </td>
                <td align="right">
                  <strong>{currencyNoCents(item.aSubTotal)}</strong>
                </td>
                <td align="right">
                  <strong>{currencyNoCents(item.bSubTotal)}</strong>
                </td>
                <td align="right">
                  <strong>{currencyNoCents(item.cSubTotal)}</strong>
                </td>
                <td align="right">
                  <strong>{currencyNoCents(item.dSubTotal)}</strong>
                </td>
                <td align="right">
                  <strong>{currencyNoCents(item.eSubTotal)}</strong>
                </td>
                <td align="right">
                  <strong>{currencyNoCents(item.fSubTotal)}</strong>
                </td>
                <td align="right">
                  <strong>{currencyNoCents(item.subTotal)}</strong>
                </td>
              </tr>
            </Fragment>
          ))}
          <tr>
            <td colSpan={9}></td>
          </tr>
          <tr>
            <th scope="row" colSpan={2}>
              Total
            </th>
            <td align="right">
              <strong>{currencyNoCents(aTotal)}</strong>
            </td>
            <td align="right">
              <strong>{currencyNoCents(bTotal)}</strong>
            </td>
            <td align="right">
              <strong>{currencyNoCents(cTotal)}</strong>
            </td>
            <td align="right">
              <strong>{currencyNoCents(dTotal)}</strong>
            </td>
            <td align="right">
              <strong>{currencyNoCents(eTotal)}</strong>
            </td>
            <td align="right">
              <strong>{currencyNoCents(fTotal)}</strong>
            </td>
            <td align="right">
              <strong>{currencyNoCents(total)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
