import React, { useEffect, useState, Fragment } from "react";
import { client, clientError } from "../../libs/Client";
import Breadcrumb from "../../components/Breadcrumb";
import EmptyRows from "../../components/EmptyRows";
import LawFirmAddress from "../../components/Statements/LawFirmAddress";
import StatementAddress from "../../components/Statements/StatementAddress";
import { currencyNoCents, reportDate, shortDate } from "../../libs/Utils";
import { PrinterIcon } from "@heroicons/react/20/solid";

const pages = [{ name: "Detailed Statement", href: "/detailed", current: true }];

export default function DetailedStatement() {
  const [serviceProvider, setServiceProvider] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [total, setTotal] = useState(0);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    client()
      .get(`/service-provider-portal/statements/detailed`)
      .then((res) => {
        setTotal(res.data.total);
        setLineItems(res.data.lineItems);
        setServiceProvider(res.data.serviceProvider.name);
        setStreetAddress(res.data.serviceProvider.streetAddress);
        setCity(res.data.serviceProvider.city);
        setState(res.data.serviceProvider.state.label);
        setZipCode(res.data.serviceProvider.zip);
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);
  return (
    <main className="flex-1">
      <Breadcrumb pages={pages} />
      <div className="py-1">
        <div className="text-right p-2">
          <a
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            href={`/print-views/detail-statement`}
            target="_blank"
            rel="noreferrer"
          >
            <PrinterIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Printer Friendly
          </a>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="grid grid-cols-2 gap-4 mt-8">
            <LawFirmAddress name={serviceProvider} street={streetAddress} city={city} state={state} zipCode={zipCode} />
            <div className="text-right">
              <StatementAddress />
            </div>
          </div>
          <div className="mt-10">
            <p className="text-center text-xl font-base text-gray-900">
              <u>Statement as of {reportDate()}</u>
            </p>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                {lineItems.length > 0 ? (
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-4 text-left text-sm font-semibold text-gray-900 align-bottom"
                            >
                              Client (Date of Accident)
                              <br />
                              <span className="ml-4">Date of Funding</span>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-4 text-left text-sm font-semibold text-gray-900 align-bottom"
                            >
                              Primary Law Firm Contact
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-4 text-right text-sm font-semibold text-gray-900 align-bottom"
                            >
                              Total Payoff
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {lineItems.map((item, idx) => (
                            <Fragment key={item.fullName}>
                              <tr key={item.fullName}>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  {item.fullName} ({shortDate(item.accidentDate)})
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">{item.contact}</td>
                                <td className="py-4 pr-4 text-right text-sm font-medium"></td>
                              </tr>
                              {item.applications.map((application, idx) => (
                                <tr key={idx}>
                                  <td className="px-8 py-2 text-sm text-gray-500">
                                    {shortDate(application.createdAt)}
                                  </td>
                                  <td></td>
                                  <td className="px-2 py-2 text-right text-sm text-gray-500">
                                    {currencyNoCents(application.amountDue)}
                                  </td>
                                </tr>
                              ))}
                              {item.partialPaymentTotal > 0 && (
                                <tr>
                                  <td className="px-8 py-2 font-base text-sm text-gray-500" colSpan={2}>
                                    Partial Payments
                                  </td>
                                  <td className="px-2 py-2 font-base text-sm text-gray-500 text-right">
                                    {currencyNoCents(-item.partialPaymentTotal)}
                                  </td>
                                </tr>
                              )}

                              <tr>
                                <td className="px-3 py-3 font-semibold text-sm text-gray-500 bg-gray-50" colSpan={2}>
                                  Case Total
                                </td>
                                <td className="px-3 py-3 font-semibold text-sm text-gray-500 bg-gray-50 text-right">
                                  {currencyNoCents(item.subTotal)}
                                </td>
                              </tr>
                            </Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th
                              scope="row"
                              colSpan={2}
                              className="bg-teal-200 text-left text-lg font-bold text-gray-900 px-2"
                            >
                              Total
                            </th>
                            <td className="px-2 py-4 text-right text-base text-gray-900 font-bold bg-teal-200">
                              {currencyNoCents(total)}
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ) : (
                  <EmptyRows
                    title="No Detailed Statement"
                    subtitle="There is currently insufficient information to generate a detailed statement"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
