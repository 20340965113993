import moment from "moment";
import numeral from "numeral";
import parsePhoneNumber from "libphonenumber-js";

export function shortDate(date) {
  if (date !== "") {
    return moment(date).format("MM/DD/YYYY");
  }

  return "";
}

export function reportDate() {
  return moment().format("LL");
}

export function longDate(date) {
  if (date && date !== "") {
    return moment(date).format("LLLL");
  }
  return "";
}

export function dateFromNow(date) {
  if (date !== "") {
    return moment(date).fromNow();
  }
  return "";
}

export function nationalPhoneNumber(phoneNumber) {
  if (phoneNumber !== "") {
    return parsePhoneNumber(phoneNumber, "US").formatNational();
  }

  return "";
}

export function currency(amount) {
  if (amount) {
    return numeral(amount / 100).format("$ 0,0.00");
  }

  return numeral(0).format("$ 0,0.00");
}

export function currencyNoCents(amount) {
  if (amount) {
    return numeral(Math.floor(amount / 100)).format("$ 0,0");
  }

  return numeral(0).format("$ 0,0");
}
