import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SettingTabs(props) {
  var tabs = [];
  if (props.selected === "Profile") {
    tabs.push({ name: "Profile", href: "/settings", current: true });
  } else {
    tabs.push({ name: "Profile", href: "/settings", current: false });
  }

  if (props.selected === "Password") {
    tabs.push({ name: "Password", href: "/settings/password", current: true });
  } else {
    tabs.push({ name: "Password", href: "/settings/password", current: false });
  }

  if (props.role === "Chapter President") {
    if (props.selected === "Agreements") {
      tabs.push({
        name: "Agreements",
        href: "/settings/agreements",
        current: true,
      });
    } else {
      tabs.push({
        name: "Agreements",
        href: "/settings/agreements",
        current: false,
      });
    }
  }

  return (
    <div className="py-6">
      {/* Tabs */}
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? "border-brand-400 text-brand-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

SettingTabs.propTypes = {
  selected: PropTypes.string.isRequired,
};
