// import { useRef } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { capitalize } from "lodash";

import SettingTabs from "../../layouts/FullLayout/SettingTabs/SettingTabs";
import Breadcrumb from "../../components/Breadcrumb";

import { useUser } from "../../hooks/Authentication";

import EditFullName from "../../components/Settings/EditFullName";
import EditEmail from "../../components/Settings/EditEmail";

// const { REACT_APP_API_URL } = process.env;

export default function Settings() {
  // const ref = useRef(null);
  const { user } = useUser();

  const pages = [{ name: "Settings", href: "/settings", current: true }];

  function handleRefresh() {
    window.location.reload(false);
  }

  return (
    <main className="flex-1">
      <Breadcrumb pages={pages} />
      <div className="py-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-2">
            <div className="max-w-7xl mx-auto">
              <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
            </div>
            <SettingTabs role={user.role} selected="Profile" />
            <div className="mt-0 divide-y divide-gray-200">
              <div className="mt-">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span className="flex-grow">
                        {user.firstName} {user.lastName}
                      </span>
                      <span className="ml-4 flex-shrink-0">
                        <EditFullName
                          firstName={user.firstName}
                          lastName={user.lastName}
                          saved={handleRefresh}
                        />
                      </span>
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <span className="flex-grow">{user.email}</span>
                      <span className="ml-4 flex-shrink-0">
                        <EditEmail email={user.email} saved={handleRefresh} />
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
