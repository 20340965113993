import React, { useEffect, useState, Fragment } from "react";
import { client, clientError } from "../../libs/Client";
import LawFirmAddress from "../../components/Statements/LawFirmAddress";
import StatementAddress from "../../components/Statements/StatementAddress";
import { currencyNoCents, reportDate, shortDate } from "../../libs/Utils";
import "../../print.css";

export default function DetailStatementPrintView() {
  const [serviceProvider, setServiceProvider] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [total, setTotal] = useState(0);
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    client()
      .get(`/service-provider-portal/statements/detailed`)
      .then((res) => {
        setTotal(res.data.total);
        setLineItems(res.data.lineItems);
        setServiceProvider(res.data.serviceProvider.name);
        setStreetAddress(res.data.serviceProvider.streetAddress);
        setCity(res.data.serviceProvider.city);
        setState(res.data.serviceProvider.state.label);
        setZipCode(res.data.serviceProvider.zip);
      })
      .catch((err) => {
        clientError(err);
      });
  }, []);

  return (
    <div>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <LawFirmAddress
                name={serviceProvider}
                street={streetAddress}
                city={city}
                state={state}
                zipCode={zipCode}
              />
            </td>
            <td align="right">
              <StatementAddress />
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        <center>
          <strong>
            <u>Statement as of {reportDate()}</u>
          </strong>
        </center>
      </p>
      <p>&nbsp;</p>
      <table className="main-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th align="left">
              Client (Date of Accident)
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;Date of Funding
            </th>
            <th align="left">Contact</th>
            <th align="right">Total Payoff</th>
          </tr>
        </thead>
        <tbody>
          {lineItems.map((item, idx) => (
            <Fragment key={item.fullName}>
              <tr key={item.fullName}>
                <td>
                  {item.fullName} ({shortDate(item.accidentDate)})
                </td>
                <td>{item.contact}</td>
                <td></td>
              </tr>
              {item.applications.map((application, idx) => (
                <tr key={idx}>
                  <td align="left">&nbsp;&nbsp;&nbsp;&nbsp;{shortDate(application.createdAt)}</td>
                  <td align="left"></td>
                  <td align="right">{currencyNoCents(application.amountDue)}</td>
                </tr>
              ))}
              {item.partialPaymentTotal > 0 && (
                <tr>
                  <td align="left" colSpan={2}>
                    Partial Payments
                  </td>
                  <td align="right">{currencyNoCents(-item.partialPaymentTotal)}</td>
                </tr>
              )}
              <tr>
                <td colSpan={2}>
                  <strong>Case Total</strong>
                </td>
                <td align="right">
                  <strong>{currencyNoCents(item.subTotal)}</strong>
                </td>
              </tr>
            </Fragment>
          ))}
          <tr>
            <td align="left">
              <strong>Total</strong>
            </td>
            <td></td>
            <td align="right">
              <strong>{currencyNoCents(total)}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
