import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import { useUser } from "../../hooks/Authentication";
import { client, clientError } from "../../libs/Client";

export default function EditEmail(props) {
  const { user, setUser } = useUser();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState(props.email);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleSave() {
    client()
      .patch(`/service-provider-portal/me`, {
        email,
      })
      .then((res) => {
        setOpen(false);
        setUser({
          accessToken: user.accessToken,
          email: res.data.email,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          role: res.data.role,
        });
        props.saved(true);
      })
      .catch((err) => {
        clientError(err);
      });
  }

  return (
    <div>
      <button className="text-brand-400 hover:text-brand-600" onClick={handleClickOpen}>
        Update
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-brand-400 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">Update Email</Dialog.Title>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-brand-600">Update your email address.</p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-400 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                                  Email
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand focus:ring-brand-600 sm:text-sm"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-brand-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-400 focus:ring-offset-2"
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

EditEmail.propTypes = {
  email: PropTypes.string.isRequired,
  saved: PropTypes.func.isRequired,
};
